<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_requests')"
                        :isFilter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_requests')"
                              :isFilter="false">
                </HeaderMobile>
            </template>
            <b-tabs content-class="py-5 position-relative" v-model="tabIndex">
                <div class="tabs-bottom-line"></div>
                <b-tab>
                    <template #title><span class="d-flex align-items-center">{{ $t('leave_of_absence') }}</span>
                    </template>
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="leaveOfAbsence"
                        :fields="leaveOfAbsenceFields"
                        @row-clicked="onRowClicked"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded"
                    >
                        <template #cell(semesters)="row"><span
                            class="d-flex align-items-center">{{ row.item.semesters }}</span>
                        </template>
                        <template #cell(steps)="row">
                            <span class="d-flex align-items-center justify-content-center" style="cursor:pointer">
                                <i class="ri-arrow-down-s-line"></i>
                            </span>
                        </template>
                        <template #row-details="data">
                            <b-table-simple responsive small class="table-bordered table-striped table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center">{{ $t('rank').toUpperCase() }}</th>
                                        <th class="text-center">{{ $t('can_approvers').toUpperCase() }}</th>
                                        <th class="text-center">{{ $t('approved_by').toUpper() }}</th>
                                        <th class="text-center">{{ $t('status').toUpper() }}</th>
                                        <th class="text-center">{{ $t('explanation').toUpper() }}</th>
                                        <th class="text-center">{{ $t('updated_at').toUpper() }}</th>
                                    </tr>
                                </thead>
                                <b-tbody v-for="(item,i) in data.item.steps" :key="i">
                                    <tr>
                                        <td class="text-center">{{ i + 1 }}</td>
                                        <td>
                                            <div v-for="(approver,i) in item.approvers" :key="i">
                                                {{ approver.explanation }}
                                            </div>
                                        </td>


                                        <td>
                                            <div v-for="(approved,i) in (getObjectValue(item,'approved',[]))" :key="i">
                                                {{
                                                    getObjectValue(approved, 'name') + ' ' + getObjectValue(approved, 'surname')
                                                }}
                                            </div>
                                        </td>


                                        <td>{{ item.status_name }}</td>


                                        <td>{{ getObjectValue(item, 'reason') }}</td>


                                        <td>{{ IsoDate(item.updated_at) }}</td>
                                    </tr>
                                </b-tbody>
                            </b-table-simple>
                        </template>
                    </b-table>
                </b-tab>

                <b-tab>
                    <template #title><span class="d-flex align-items-center">{{ $t('leave_with_consent') }}</span>
                    </template>
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="leaveWithConsent"
                        :fields="leaveWithConsentFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                        <template v-slot:cell(reason_text)="row">
                            {{ row.item.reason_text }}
                        </template>
                    </b-table>
                </b-tab>

                <b-tab>
                    <template #title><span class="d-flex align-items-center">{{ $t('refund') }}</span></template>
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="refund"
                        :fields="refundFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                    </b-table>
                </b-tab>

                <b-tab>
                    <template #title><span class="d-flex align-items-center">{{ $t('course_transfer') }}</span>
                    </template>
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="courseTransfer"
                        :fields="courseTransferFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded">
                        <template #cell(buttons)="row">
                            <b-dropdown variant="link btn-sm" boundary="window" no-caret
                                        v-if="row.item.status == 'waiting_student_approve'">
                                <template #button-content>
                                    <i class="ri-more-fill"></i>
                                </template>
                                <div>
                                    <b-dropdown-item
                                        v-if="row.item.status == 'waiting_student_approve'"
                                        @click="showTransferredCourses(row.item)">
                                        <i class="ri-eye-line align-middle top-minus-1 mr-3 text-muted"></i>
                                        {{ $t('show') }}
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </template>
                        <template #cell(status)="row">

                        </template>
                    </b-table>
                </b-tab>

                <b-tab>
                    <template #title><span class="d-flex align-items-center">{{ $t('document_request') }}</span>
                    </template>
                    <b-table
                        :empty-text="this.$t('there_are_no_records')"
                        bordered
                        striped
                        responsive
                        :items="documentRequests"
                        :fields="documentRequestFields"
                        show-empty
                        class="mb-5 table-dropdown no-scrollbar border rounded document_request_dropdown">
                        <template #cell(buttons)="row">
                            <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                                <template #button-content>
                                    <i class="ri-more-fill"></i>
                                </template>
                                <div id="dropdownListHead">
                                    <b-dropdown-item @click="cancelRequest(row.item.id)"
                                                     v-if="checkPermission('documentrequest_cancel') && ['waiting','waiting_payment'].includes(row.item.status_code)">
                                        <i class="ri-close-circle-line align-middle top-minus-1 mr-3 text-muted"></i>
                                        {{ $t('cancel') }}
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        v-if="row.item.delivery_type_code == 'online' && row.item.signature_type_code=='electronic' && row.item.status_code=='ready' && checkPermission('documentrequest_downloaddocument')"
                                        @click="downloadDocument(row.item)">
                                        <i class="ri-file-download-line align-middle top-minus-1 mr-3 text-muted"></i>
                                        {{ $t('download') }}
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </template>
                        <template #cell(status)="row">
                            <router-link class="d-flex flex-column" style="gap:4px" to="/epayment" tag="div">
                                <div style="text-align: center">{{row.item.status}}</div>
                                <b-button variant="primary" style="line-height: 1" v-if="row.item.status === 'Ödeme Bekliyor'">{{
                                        $t('make_payment')
                                    }}</b-button>
                            </router-link>
                        </template>
                    </b-table>
                </b-tab>
            </b-tabs>
            <CommonModal id="transferredCoursesModal" size="xl" :onHideOnlyX="true" :footer="false"
                         :footer-class="'justify-content-center'">
                <template v-slot:CommonModalContent>
                    <div>
                        <div v-if="slots!=null">
                            <table class="table table-bordered">
                                <tr>
                                    <th>{{ $t('slot_type').toUpper() }}</th>
                                    <th class="width-100">{{ $t('course_code').toUpper() }}</th>
                                    <th>{{ $t('course_name').toUpper() + ' / ' + $t('elective_pool').toUpper() }}</th>
                                    <th class="w-40">{{ $t('credit').toUpper() }}</th>
                                    <th class="w-40">{{ $t('semester').toUpper() }}</th>
                                </tr>

                                <tr v-for="slot in slots" v-bind:key="slot.id">
                                    <td>{{ slot.slot_type ? slot.slot_type.name : "" }}</td>
                                    <td>{{ slot.course ? slot.course.code : '' }}</td>
                                    <td>{{
                                            slot.course ? slot.course.name : (slot.pool ? slot.pool.name : '')
                                        }}
                                    </td>
                                    <td>{{ slot.credit }}</td>
                                    <td>{{ slot.semester }}</td>
                                    <td>
                                        <div v-if="slot.transferred_courses">
                                            <p v-for="course in slot.transferred_courses" v-bind:key="course.id">
                                                <span>
                                                <b>{{
                                                        $t('university')
                                                    }}:</b> {{ course.university_course.university.name }}<br>
                                                <b>{{ $t('course_code') }}:</b> {{ course.university_course.code }} <b>{{
                                                        $t('course_name')
                                                    }}:</b> {{
                                                        course.university_course.name
                                                    }} <b>{{ $t('grade') }}:</b> {{
                                                        course.letter_grade
                                                    }} ({{ course.converted_letter_grade }})
                                                <b>{{ $t('credit') }}:</b> {{ course.credit }}
                                                </span>
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <ValidationObserver ref="studentApproveForm">
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('status')">
                                            <multi-selectbox :multiple="false"
                                                             :options="[{value:'approved', text: $t('i_approve')}, {value:'declined', text: $t('i_not_approve')}]"
                                                             v-model="studentApproveForm.student_approve_status"
                                                             :state="errors[0] ? false : (valid ? true : null)">
                                            </multi-selectbox>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                                <div class="col-12" v-if="studentApproveForm.student_approve_status=='declined'">
                                    <ValidationProvider name="explanation" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea
                                                v-model="studentApproveForm.student_explanation"
                                                :state="errors[0] ? false : (valid ? true : null)">
                                            </b-form-textarea>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                                     v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <b-form-group>
                                        <b-button variant="primary"
                                                  @click="saveCourseTransferStatus"/>
                                    </b-form-group>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import LeaveOfAbsenceService from "@/services/LeaveOfAbsenceService";
import LeaveWithConsentService from "@/services/LeaveWithConsentService";
import RefundRequestService from "@/services/RefundRequestService";
import CourseTransferService from "@/services/CourseTransferService";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DocumentRequestService from "@/services/DocumentRequestService";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";

import moment from 'moment';

export default {
    components: {
        MultiSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            tabIndex: 0,
            slots: null,
            selectedCourseTransfer: null,
            studentApproveForm: {},
            leaveOfAbsence: null,
            leaveOfAbsenceFields: [
                {key: 'steps', label: '',},
                {
                    key: ('program_name'),
                    label: this.toUpperCase('program'),
                },
                {key: 'academic_year', label: this.toUpperCase('academic_year')},
                {key: 'semesters', label: this.toUpperCase('semester')},
                {key: ('reason_name'), label: this.toUpperCase('reason')},
                {key: 'explanation', label: this.toUpperCase('explanation')},
                {key: 'created_at', label: this.toUpperCase('date')},
                {key: 'active_approver', label: this.toUpperCase('at_which_unit')},
                {key: ('status_name'), label: this.toUpperCase('status')},
            ],

            leaveWithConsent: null,
            leaveWithConsentFields: [
                {
                    key: ('program_name'),
                    label: this.toUpperCase('program'),
                },
                {key: 'academic_year', label: this.toUpperCase('academic_year')},
                {key: 'reason_text', label: this.toUpperCase('reason')},
                {key: 'explanation', label: this.toUpperCase('explanation')},
                {key: 'created_at', label: this.toUpperCase('date')},
                {key: 'active_approver', label: this.toUpperCase('at_which_unit')},
                {key: ('status_text'), label: this.toUpperCase('status')},
            ],

            refund: null,
            refundFields: [
                {
                    key: ('program_name'),
                    label: this.toUpperCase('program'),
                },
                {key: 'account_holder', label: this.toUpperCase('account_holder')},
                {key: 'bank_name', label: this.toUpperCase('bank_name')},
                {key: 'branch_name', label: this.toUpperCase('branch_name')},
                {key: ('reason_name'), label: this.toUpperCase('reason')},
                {key: ('status_name'), label: this.toUpperCase('status')},
                {key: 'reason_for_rejection', label: this.toUpperCase('reason_for_rejection')},
                {key: 'created_at', label: this.toUpperCase('date')},
            ],

            courseTransfer: null,
            courseTransferFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: ('program_name'),
                    label: this.toUpperCase('program'),
                },
                {key: 'created_at', label: this.toUpperCase('date')},
                {key: 'calculated_cgpa', label: this.toUpperCase('calculated_cgpa')},
                {key: 'active_approver', label: this.toUpperCase('at_which_unit')},
                {key: ('status_name'), label: this.toUpperCase('status')},
            ],

            documentRequests: [],
            documentRequestFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: 'document_type',
                    label: this.$t('document_type'),
                },
                {
                    key: 'count',
                    label: this.$t('count'),
                },
                {
                    key: 'date',
                    label: this.$t('date').toUpper(),
                },
                {
                    key: 'signature_type',
                    label: this.$t('signature_type'),
                },
                {
                    key: 'type_of_delivery',
                    label: this.$t('type_of_delivery').toUpper(),
                },
                {
                    key: 'address',
                    label: this.$t('address').toUpper(),
                },
                {
                    key: 'explanation',
                    label: this.$t('explanation'),
                },
                {
                    key: 'price',
                    label: this.$t('price'),
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
            ],

            stateData: {}
        }
    },
    metaInfo() {
        return {
            title: this.$t("my_requests")
        }
    },
    methods: {
        loadData() {
            this.stateData.active_student_program = {id: 48860};
            if (!this.stateData || !this.stateData.active_student_program) {
                return;
            }

            LeaveOfAbsenceService.studentRequests()
                .then(response => {
                    this.leaveOfAbsence = response.data.data;
                    this.leaveOfAbsence.forEach(item => {
                        if (item.active_step && item.active_step.approvers) {
                            let arr = [];
                            item.active_step.approvers.forEach(approver => {
                                arr.push(approver.explanation)
                            })
                            item.active_approver = arr.join(', ');
                        }
                    })
                }).catch(e => {
                this.showErrors(e);
            });

            LeaveWithConsentService.studentRequests()
                .then(response => {
                    this.leaveWithConsent = response.data.data;
                    this.leaveWithConsent.forEach(item => {
                        if (item.active_step && item.active_step.approvers) {
                            let arr = [];
                            item.active_step.approvers.forEach(approver => {
                                arr.push(approver.explanation)
                            })
                            item.active_approver = arr.join(', ');
                        }
                    })
                }).catch(e => {
                this.showErrors(e);
            });

            RefundRequestService.studentRequests()
                .then(response => {
                    this.refund = response.data.data;
                }).catch(e => {
                this.showErrors(e);
            });

            this.loadCourseTransfers();

            DocumentRequestService.getStudentDocuments(this.stateData.active_student_program.id).then(response => {
                this.documentRequests = []
                response.data.data.forEach(item => {
                    this.documentRequests.push({
                        document_type: item.document_type,
                        count: item.count,
                        date: item.date,
                        signature_type: item.signature_type,
                        type_of_delivery: item.delivery_type,
                        address: item.address,
                        explanation: item.explanation,
                        delivery_type_code: item.delivery_type_code,
                        signature_type_code: item.signature_type_code,
                        status_code: item.status_code,
                        price: item.price == 0 ? this.$t('priceless') : item.price + ' TL',
                        status: item[('status')],
                        id: item.id
                    })
                })
            })
        },
        IsoDate(date) {
            return moment(date).utc().format("MM/DD/YYYY HH:mm");
        },
        loadCourseTransfers() {
            CourseTransferService.studentRequests()
                .then(response => {
                    this.courseTransfer = response.data.data;
                    this.courseTransfer.forEach(item => {
                        if (item.active_step && item.active_step.approvers) {
                            let arr = [];
                            item.active_step.approvers.forEach(approver => {
                                arr.push(approver.explanation)
                            })
                            item.active_approver = arr.join(', ');
                        }
                    })
                }).catch(e => {
                this.showErrors(e);
            });
        },
        cancelRequest(id) {
            this.$swal.fire({
                text: this.$t('document_request_cancel_statement'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then(response => {
                if (response.isConfirmed == true) {
                    DocumentRequestService.cancelRequest(id)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(err => this.showErrors(err))
                }
            }).then(() => {
                this.loadData()
            })
        },

        downloadDocument(row) {
            DocumentRequestService.downloadDocument(this.stateData.active_student_program.id, row.id).then(response => {
                this._downloadFile(response, row.document_type + '.pdf')
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message));
            })
        },
        onRowClicked(item, index, event) {
            this.$set(item, '_showDetails', !item._showDetails);
            this.slotsDetailsRow = item._showDetails ? item : false;
            this.slotsSelectIndex = item._showDetails ? index : null;
        },
        showTransferredCourses(row) {
            CourseTransferService.studentTransferredCourses(row.id)
                .then(response => {
                    this.slots = response.data.data;
                    this.$bvModal.show('transferredCoursesModal');
                });
            this.selectedCourseTransfer = row;
        },
        async saveCourseTransferStatus() {
            const isValid = await this.$refs.studentApproveForm.validate();
            if (isValid) {
                CourseTransferService.studentApprove(this.selectedCourseTransfer.id, this.studentApproveForm)
                    .then(response => {
                        this.$toast.success(this.$t("api." + response.data.message));
                        this.$bvModal.hide('transferredCoursesModal');
                        this.loadCourseTransfers();
                    })
                    .catch((e) => {
                        if (e.data.message) {
                            this.$toast.error(this.$t("api." + e.data.message));
                        }
                    });
            }
        },
    },
    created() {
        this.$store.dispatch("auth/initUser");
        this.stateData = {
            ...this.$store.getters['auth/getUser']
        }

        this.loadData();
        if (this.$route.params.tab) {
            this.tabIndex = parseInt(this.$route.params.tab)
        }
    },
}
</script>

<style lang="scss">
.document_request_dropdown {
    tr {
        td:first-child {
            padding-right: 0px !important;
        }
    }
}
</style>
